import * as React from "react"
import styled from "styled-components";
import {Link} from "gatsby";

const ButtonStyled = styled(Link)`
  background-color: #02A6D8;
  color: #fff;
  border-radius: 14px;
  font-family: 'PT Sans', sans-serif;
  padding: 14px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: block;
`;

const ButtonRow = styled.div`
  padding: 10px;
  width: 100%;
`;

const Button = ({to, children}) => (
  <ButtonRow>
    <ButtonStyled to={to}>
      {children}
    </ButtonStyled>
  </ButtonRow>
)

export default Button;